import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

import { PageMeta } from '../components/pageMeta';
import { Footer } from '../components/nav/footer';
import BlockZone from '../components/blockZone/zone.development';

const IndexPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { meta, pageBlocks, pageTheme } = page || {};

  useEffect(() => {
    if (pageTheme) {
      const themeArray = Object.entries(pageTheme);
      themeArray.forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, `${value.hex}`);
      });
    }
  }, [pageTheme]);

  return (
    <>
      <PageMeta {...meta} />

      {pageBlocks && <BlockZone {...pageBlocks} pageTheme={pageTheme} />}
      <Footer />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      pageTheme {
        ...Theme
      }
    }
  }
`;
